import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const UnsubscribeOptionContainer = styled.div`
  background: ${(props) => props.theme.palette.background.bg1};
  padding: 24px;
  box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  margin-top: 40px;
  width: 840px;
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider.d1};
  padding-bottom: 16px;
  display: grid;
  place-items: center;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const FormTitle = styled.h1`
  font-size: 32px;
  font-family: Onest-Bold;
  line-height: 36px;
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 20px;
    line-height: 24px;
    margin: 8px;
  }
`;

export const FormContainer = styled.div`
  margin-top: 16px;
  display: grid;
  place-items: center;
`;

export const InputContainer = styled.div`
  background: ${(props) => props.theme.palette.background.bg3};
  display: flex;
  gap: 16px;
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.t2};
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 20px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
`;
