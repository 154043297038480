import Head from 'next/head';
import SiteLayout from '../../Layout/SiteLayout';
import UnsubscribeEmail from '../../components/UnsubscribeEmail/UnsubscribeEmail';
import { BodyContainer } from '../../styles/pageStyles/unsubscribeStyle';
import { getUserInfoSSR } from '../../utils/cf';

const Unsubscribe = () => {
  return (
    <>
      <Head>
        <title>Unsubscribe - Driffle </title>
      </Head>
      <SiteLayout>
        <BodyContainer>
          <UnsubscribeEmail />
        </BodyContainer>
      </SiteLayout>
    </>
  );
};

export default Unsubscribe;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
