import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { apiConstants } from '../../constants/apiConstants';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useNewsletter } from '../../contexts/NewsletterContext';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logoutUserServer } from '../../redux/userSlice';
import LoaderCircle from '../LoaderDots/LoaderCircle';
import Button from '../NewButton/Button';
import {
  ButtonContainer,
  FormContainer,
  FormTitle,
  InputContainer,
  TitleContainer,
  UnsubscribeOptionContainer,
} from './UnsubscribeEmailStyle';

const UnsubscribeEmail = () => {
  const router = useRouter();
  const [selected, setSelected] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const { cleverTap } = useAnalytics();
  const [pageState, setPageState] = React.useState<
    'form' | 'loading' | 'success'
  >('form');

  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { handleNewsletterCLick } = useNewsletter();

  const { dispatchSnackbar } = React.useContext(SnackbarContext);

  const onUnsubscribeClick = React.useCallback(() => {
    setPageState('loading');
    if (!selected) {
      setPageState('form');
      return;
    }

    if (router.query.type === 'newsletter') {
      handleNewsletterCLick(String(email), 'unsubscribe')
        .then((res) => {
          setPageState('success');
        })
        .catch((err) => {
          dispatchSnackbar({
            type: 'failure',
            payload: 'Error saving preference',
          });
          setPageState('form');
        });
    } else {
      axios
        .get(
          apiConstants.server +
            `/public/unsubscribe-mail?user=${router.query.email}&type=${router.query.type}`
        )
        .then((res) => {
          setPageState('success');
        })
        .catch((err) => {
          dispatchSnackbar({
            type: 'failure',
            payload: 'Error saving preference',
          });
          setPageState('form');
        });
    }
  }, [JSON.stringify(router.query), selected]);

  useEffect(() => {
    if (router.isReady && typeof router.query.email === 'string') {
      axios
        .get(
          apiConstants.server + `/public/dec-email?user=${router.query.email}`
        )
        .then((res) => {
          setEmail(res.data.email);
          if (user.isLoggedIn && user.user?.email !== res.data.email) {
            dispatch(logoutUserServer(router, cleverTap));
          }
        })
        .catch((err) => {});
    }
  }, [router.isReady, JSON.stringify(router.query), cleverTap]);

  return (
    <>
      <UnsubscribeOptionContainer>
        {pageState === 'success' ? (
          <TitleContainer>
            <FormTitle>Preference Saved</FormTitle>
            <div>
              To change your email preferences login and update in profile
            </div>
          </TitleContainer>
        ) : (
          <>
            <TitleContainer>
              <FormTitle>Manage email preferences</FormTitle>
              <div>Your email address is {email}</div>
            </TitleContainer>
            <FormContainer>
              <InputContainer onClick={() => setSelected(!selected)}>
                <input
                  type="checkbox"
                  //   onChange={() => setSelected((value) => !value)}
                  readOnly
                  checked={selected}
                />
                <div>I no longer want to receive promotional emails</div>
              </InputContainer>
              <ButtonContainer>
                <Button
                  onClick={pageState === 'form' ? onUnsubscribeClick : () => {}}
                  ariaLabel="unsubs"
                  disabled={!selected}
                >
                  {pageState === 'form' ? 'Save Preferences' : <LoaderCircle />}
                </Button>
              </ButtonContainer>
            </FormContainer>
          </>
        )}
      </UnsubscribeOptionContainer>
    </>
  );
};

export default UnsubscribeEmail;
