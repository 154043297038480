import styled from 'styled-components';

export const BodyContainer = styled.div`
  @media (min-width: 480px) {
    margin: 0 20px;
  }
  @media (min-width: 800px) {
    display: flex;
    margin: 0 20px;
  }
  @media (min-width: 1200px) {
    max-width: 1120px;
    width: 100%;
    margin: auto;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  //   justify-content: center;
  min-height: 60vh;
`;
